ul{
  padding: 0;
}
ul > li{
  list-style: none;
  padding: 0;
  margin: 0;
}

.demo-logo > h2 > a{
  background: url(https://github.com/yan-tingciou/goldeggphone/blob/main/images/3.png?raw=true) no-repeat ;
  width: 97px;
  height: 100px;
  display: inline-block;
  text-indent: 105%;
  overflow: hidden;
  white-space: nowrap;
  margin: 45px 0 0 ;
  /* margin-top: 40px;  */
}

.link {
	display: block;
	color: #fff;
  padding: 0 18px;
  margin: 0 5px;
  position: relative;
  text-decoration: none;
  font-weight: bold;
  border-bottom: 3px solid transparent;
  transition: border-bottom 0.3s;
}
.link:hover{
  transition: all 0.5s;
  color: #ff8c00;
}
.link.active{
  color: #ff8c00;
  border-bottom: 4px solid #ff8c00;
}
.headerStyle{
  position: sticky;
  top: 0;
  z-index: 100;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
@media (max-width: 690px){
  /* .headerStyle{
    display: flex;
    justify-content: space-between;
    align-items: center;
  } */
  .demo-logo {
    margin: 0 auto;
  }
}
.menu{
  position: sticky;
  top: 110px;
  z-index: 100;
  display: flex;
  font-size: 16px;
  padding: 0px;
}
.menu.small{
  margin-top: -16px;
}
.menu-list {
  font-size: 24px;
  /* display: none; */
  color: #fff;
}
.menu-list:hover{
  color: #ff8c00;  
}

@media (max-width: 690px){
  .menu{
    position: sticky;
    top: 110px;
    z-index: 100;
    display: flex;
    flex-direction: column;
    background: #272727;
  }
  .menu-list{
    order: 2;
    display: block;
    float: right;
  }
  .menu > li > a{
    padding-top: 8px;
    height: 42px;
  }
}

.icon_fb:hover, .icon_line:hover, .icon_call:hover{
  opacity: 60%;
}

.bgStyle{
  width: 100%;
	height: 600px;
	object-fit: none;
}
.contentStyle{
  min-height: 578px;
  background: #fff;
  padding: 30px;
}
@media (max-width: 690px){
  .contentStyle{
    padding: 10px;
  }
}

/* service */
.serviceUl > li {
  display: flex;
  justify-content: space-around;
  align-items: end;
  margin: 0 2%;
  padding-bottom: 100px;
}
.serviceLiReverse{
  flex-direction: row-reverse;
}
.serviceText{
  margin: 0 2%;
  margin-left: 2%;
  width: 40%;
}
.servicePhoto{
  height: 320px;
}
.serviceText > p {
  font-size: 18px;
}
@media (max-width: 960px){
  .serviceUl > li {
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 30px;
  }
  .servicePhoto{
    margin: 0 auto;
  }
  .serviceText{
    display: block;
    padding: 0 5%;
    width: 100%;
  }
  .serviceText > p {
    font-size: 16px;
  }
  .reverse{
    flex-direction: column;
  }
}
@media (max-width: 690px){
  .servicePhoto{
    height: 320px;
    width: 100%;
    object-fit: contain;
  }
}

/* price */
.columnBold{
  font-weight: bold;
}
.priceBgColor{
  background-color: #ff8c00;
}
.fontRed{
  color: red;
}
.aboutText{
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 0 2%;
  padding-bottom: 8em;
}
.aboutText > p {
  width: 50%;
  margin: 0 2%;
  font-size: 18px;
}

@media (max-width: 690px){
  .aboutText{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 2%;
    padding-bottom: 60px;
  }
  .aboutText > p {
    width: 98%;
    margin-top: 20px;
    font-size: 16px;
  }
  .aboutTextCenter{
    text-align: center;
  }
}
